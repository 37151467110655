section#contactform {
  position: relative;
  background: $whiteBg;
  padding: 120px 0;
  overflow: hidden;
  .block__info {
    width: 460px;
  }
  .block__info-title {
    color: $red;
    span {
      color: $dark;
    }
  }
  .block__info-subtitle {
    width: 316px;
  }
  .pattern {
    position: absolute;
    bottom: 0%;
    left: -34%;
    width: 70%;
    svg path {
      fill: #f8f8f8;
      stroke: #1d232e;
    }
  }
  .about__call {
    transform: translate(0);
    z-index: 10;
  }
}
.contactform {
  display: flex;
  justify-content: space-between;
  .colum {
    width: 50%;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    .colum:first-child {
      width: 60%;
    }
    .colum:last-child {
      width: 70%;
    }
    &-polytic {
      color: $dark-blue;
      @include s400;
      font-size: 14px;
      line-height: 150%;
      a {
        color: $red;
        text-decoration: underline;
      }
    }
  }
  input,
  textarea {
    @include g400;
    border: 0;
    outline: 0;
    width: -webkit-fill-available;
    padding: 25px 40px 25px 40px;
    color: $dark;
    border: 2px solid $gray;
    margin-bottom: 30px;
    resize: none;
    &::placeholder {
      color: $dark;
      opacity: 0.3;
    }
  }
  textarea {
    height: 170px;
  }
  &__call {
    background: $whiteBg;
    padding-bottom: 115px;
    .about__call {
      overflow: initial;
      transform: translate(0);
      .rs {
        right: -20px;
        bottom: 5px;
      }
      &-image {
        right: 0;
        top: -26%;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  section#contactform {
    padding: 90px 0;
  }
  input,
  textarea {
    font-size: 12px;
  }
  .contactform-footer-polytic {
    font-size: 11px;
  }
}

@media (max-width: 869px) {
  #contactform {
    .pattern {
      display: none;
    }
  }
  .contactform {
    flex-direction: column;
    .colum {
      width: 100%;
    }
    &-footer {
      flex-direction: column;
      .colum:first-child,
      .colum:last-child {
        width: 100%;
        text-align: center;
      }
    }
  }
  .contactform__call {
    .about__call-image {
      width: 500px;
      position: absolute;
      bottom: 0;
      top: 50%;
    }
    .about__call {
      .rs,
      .about__call-image {
        font-size: 200px;
        right: 50%;
        bottom: -45px;
        transform: translateX(50%);
      }
    }
  }
}

@media (max-width: $mobile-width) {
}
