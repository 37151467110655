section#vacancypage {
  height: auto;
  background: url(./../assets/images/staticPatternWhite.png) no-repeat $whiteBg;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 120px;
  .about__call {
    transform: translate(0);
    overflow: initial;

    .rs {
      right: -20px;
      bottom: 5px;
    }
    &-image {
      right: 0;
      top: -26%;
    }
  }
}

.vacancypage__header {
  position: relative;
  background-position: 67% 35%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 700px;
  &-image {
    position: absolute;
    top: 137px;
    z-index: 5;
    right: 0;
    height: calc(100% - 133px);
    img {
      height: 100%;
    }
  }
}
.vacancyOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $dark;
  opacity: 0.9;
  z-index: 1;
}

.vacancy__rightside {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  @include s400;
  &-title {
    @include h700;
    color: $white;
    font-size: 30px;
    text-transform: uppercase;
    max-width: 442px;
    margin-bottom: 100px;
  }
  &-back {
    color: $dark-blue;
    position: relative;
    cursor: pointer;
    margin: 75px 0 45px 0;
    svg {
      transform: rotate(-180deg);
      margin-right: 15px;
    }
  }
}

@media (max-width: $tablet-width) {
  section#vacancypage {
    .about__call {
      .rs {
        font-size: 250px;
        right: -11px;
        bottom: -29px;
      }
      &-image {
        max-width: 400px;
      }
    }
  }
  .vacancypage__header {
    max-height: 500px;
  }
  .vacancy__rightside {
    &-title {
      font-size: 25px;
      max-width: 355px;
      margin-bottom: 75px;
    }
    &-back {
      font-size: 14px;
      margin: 60px 0 30px 0;
    }
  }

  .vacancy__about {
    .colum:first-child {
      padding: 40px;
    }
    &-title {
      margin-bottom: 30px;
      font-size: 12px;
      margin-bottom: 20px;
    }
    &-conditions-title {
      font-size: 12px;
    }
    &-conditions-list {
      margin: 15px 0 30px 0;
    }
    .vacancy__about-conditions {
      &-list {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 869px) {
  section#vacancypage {
    .vacancypage__header {
      height: 100vh;
      max-height: 100vh;
      padding: 0;
      &-image {
        top: initial;
        bottom: 0;
        left: 0;
        height: auto;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  section#vacancypage {
    .about__call {
      &-image {
        width: 400px;
        bottom: -50px;
        top: initial;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .vacancy__about {
    flex-direction: column;
    margin-top: 125%;
    .colum:first-child {
      width: 100%;
      margin-bottom: 30px;
    }
    &-conditions-list {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: $mobile-width) {
}
