.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  transition: all 1s;
  transition-delay: 3s;
  .pattern {
    position: absolute;
    bottom: -40.5%;
    left: 0;
    transform: translateY(0%);
  }
  .logo {
    position: relative;
    left: 0px;
    top: 50%;
    transform: translateY(-50%) scale(1.4);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $dark;
      opacity: 0.9;
      animation: i0293 2s linear forwards;
    }
  }
}
.gotop {
  animation: goTopDesktop 1s 2s cubic-bezier(0.95, 0.17, 0.85, 0.88) forwards;
}
.gobottom {
  animation: goBottom 4s cubic-bezier(0.43, 0, 0.69, 1) forwards;
}
.fadeOut {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: $tablet-width) {
}

@media (max-width: 869px) {
  .preloader {
    .logo {
      left: 0px;
    }
  }
  .gotop {
    animation: goTopMobile 1s 2s cubic-bezier(0.95, 0.17, 0.85, 0.88) forwards;
  }
}

@media (max-width: $mobile-width) {
}

@keyframes i0293 {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
@keyframes goBottom {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes goTopDesktop {
  0% {
    top: 50%;
    transform: translateY(-50%) scale(1.4);
  }
  60% {
    top: 30px;
    left: 50px;
    transform: translateY(0%) scale(1.3);
  }
  100% {
    top: 30px;
    left: 50px;
    transform: translateY(0%) scale(1);
  }
}

@keyframes goTopMobile {
  0% {
    top: 50%;
    transform: translateY(-50%) scale(1.4);
  }
  60% {
    top: 30px;
    transform: translateY(0%) scale(1.3);
  }
  100% {
    top: 30px;
    transform: translateY(0%) scale(1);
  }
}
