.historyitem {
  box-sizing: border-box;
  padding: 30px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(29, 35, 46, 0.1);
  &:last-child {
    border: none;
  }
  &-year {
    transition: all 0.5s;
    @include h700;
    color: $dark-blue;
    font-size: 36px;
  }
  &-image {
    position: absolute;
    top: 50%;
    left: 43%;
    transform: translate(-100%, -50%);
    transition: all 1s;
    width: 255px;
    opacity: 0;
    visibility: hidden;
    img {
      width: 255px;
      height: 282px;
    }
  }
  &-chronology {
    width: 45%;
  }
  .chronology-event {
    font-size: 18px;
    @include s400;
    color: $dark;
    line-height: 150%;
    position: relative;
    margin-bottom: 15px;
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: $red;
      border-radius: 100%;
      left: -8%;
      top: 8px;
    }
  }
  &:hover {
    .historyitem-image {
      opacity: 1;
      visibility: visible;
    }
    .historyitem-year {
      color: $red;
    }
  }
}

@media (max-width: $tablet-width) {
  .historyitem {
    &-year {
      font-size: 25px;
    }
    &-image {
      left: 50%;
      img {
        width: 155px;
        height: auto;
      }
    }
    .chronology-event {
      font-size: 14px;
    }
  }
}

@media (max-width: 868px) {
  .historyitem {
    &-year {
      font-size: 16px;
    }
    &-image {
      left: 50%;
      transform: translate(-75%, -25%);
      img {
        width: 80px;
        height: auto;
      }
    }
    .chronology-event {
      font-size: 10px;
      &::before {
        width: 6px;
        height: 6px;
        left: -15%;
        top: 5px;
      }
    }
  }
}
