section#directions {
  position: relative;
  background: $dark;
  z-index: 1;
  overflow: hidden;
}
.bgDirection {
  position: absolute;
  top: 10%;
  background: url(./../assets/images/staticPattern.png) no-repeat $dark;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.call {
  padding-top: 100px;
  background: #fff;
  .about__call {
    transform: translateY(0);
    overflow: initial;
    margin: 100px 0;
    .rs {
      right: -20px;
      bottom: 5px;
    }
    .about__title {
      max-width: 400px;
    }
    &-image {
      animation: soaring 10s ease-in-out infinite;
    }
  }
}
.direction {
  display: flex;
  flex-direction: column;
  &__cart {
    opacity: 0;
    position: relative;
    box-shadow: 0px 0px 26px 0px rgba(34, 60, 80, 0.2);
  }
  &__cart:nth-child(2) {
    transform: translateX(-100%);
    animation: turnRight 2s cubic-bezier(0.44, 0.47, 0.14, 1.07) forwards;
  }
  &__cart:nth-child(3) {
    transform: translateX(100%);
    animation: turnLeft 2.5s cubic-bezier(0.44, 0.47, 0.14, 1.07) forwards;
  }
  &__cart:nth-child(4) {
    transform: translateX(-100%);
    animation: turnRight 2s cubic-bezier(0.44, 0.47, 0.14, 1.07) forwards;
  }

  &-title {
    @include h700;
    font-size: 36px;
    max-width: 340px;
    text-transform: uppercase;
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    span {
      &:first-child {
        color: $red;
        margin-left: -50px;
      }
      &:last-child {
        color: $white;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .direction {
    &-title {
      font-size: 25px;
    }
  }
  .call {
    padding: 30px 0 30px;
    .about__call {
      .rs {
        font-size: 250px;
        right: -11px;
        bottom: -29px;
      }
    }
  }
}

@media (max-width: 869px) {
  .direction {
    &-title {
      font-size: 18px;
    }
    &-title {
      span {
        &:first-child {
          color: $red;
          margin-left: 0px;
        }
        &:last-child {
          color: $dark;
        }
      }
    }
  }
  .call {
    .about__call {
      .rs {
        font-size: 200px;
        right: 50%;
        bottom: -45px;
        transform: translateX(50%);
      }
    }
  }
}

@media (max-width: $mobile-width) {
}

@keyframes turnRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes turnLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
