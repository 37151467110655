section#aboutIntro {
  box-sizing: border-box;
  height: 100vh;
  background: url("../../assets/images/about.png") no-repeat center center $dark;
}
.aboutIntro__inside {
  display: flex;
  justify-content: space-between;
  &-title {
    @include h700;
    font-size: 36px;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 60px;
    margin-top: 90px;
  }
  &-text {
    @include s400;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 100px;
    &-prev {
      font-size: 20px;
      color: $white;
      line-height: 150%;
      text-transform: uppercase;
      @include h700;
      margin-bottom: 30px;
      span {
        background: $red;
        box-sizing: border-box;
        padding: 5px 10px;
      }
    }
  }
  .colum {
    width: 50%;
  }
  .colum:first-child {
    margin-right: 60px;
  }
}

@media (max-width: $tablet-width) {
  .aboutIntro__inside {
    &-text {
      font-size: 14px;
    }
    &-title {
      font-size: 25px;
    }
  }
}
@media (max-width: 869px) {
  section#aboutIntro {
    height: auto;
  }
  .aboutIntro__inside {
    flex-direction: column;
    .colum {
      width: 100%;
    }
    &-title {
      font-size: 18px;
    }
    &-text {
      font-size: 12px;
      &-prev {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: $mobile-width) {
}
