.modal {
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  .logo {
    z-index: 4;
    &_title {
      font-size: 18px;
    }
    &_subtitle {
      font-size: 11px;
    }
    svg {
      width: 60px;
      height: 60px;
    }
    &__icon {
      margin-right: 20px;
    }
  }
  &__content {
    display: flex;
    background: $dark;
    width: 40%;
    height: 50%;
    transform: translateY(-100px);
    transition: all 0.5s;
    &.active {
      transform: translateY(0px);
    }
    &-left {
      background: url(../../assets/images/Jobs/bg.png) no-repeat;
      background-position: 67% 35%;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      position: relative;
      width: 60%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-right {
      position: relative;
      width: 40%;
      &-inside {
        box-sizing: border-box;
        padding-top: 100px;
        padding-left: 20px;
        position: absolute;
        right: -50px;
        top: -50px;
        height: 100%;
        width: 100%;
        background: $whiteBg;
        box-shadow: 0px 0px 18px 22px rgba(34, 60, 80, 0.19);
        transition: 0.5s all;
        transform: translateX(100px);
        &.active {
          transform: translateX(0px);
        }
        div {
          color: $dark;
          @include h700;
        }
        .feed {
          @include s400;
        }
        .thank {
          text-transform: uppercase;
          color: $red;
        }
        .button {
          position: absolute;
          right: -10%;
          bottom: 20%;
          box-shadow: 0px 0px 18px 3px rgba(34, 60, 80, 0.19);
          div {
            color: #fff;
          }
        }
      }
    }
  }
  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

@media (max-width: 869px) {
  .modal__content {
    height: 40%;
    display: flex;
    flex-direction: column;
    width: 80%;
    &-left,
    &-right {
      width: 100%;
    }
    &-right-inside {
      z-index: 12;
      right: 12px;
      top: -100px;
      height: auto;
      padding: 20px 10px 50px 10px;
      .button {
        bottom: -10%;
      }
    }
  }
}
