html,
body {
  height: 100%;
}

body {
  background-color: $dark;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: hidden;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: $red;
}
a {
  color: $white;
  text-decoration: none;
  border: none;
  outline: none;
  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}
button {
  outline: none;
  border: none;
}
ul,
li {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
img {
  vertical-align: middle;
  border-style: none;
  border: 0px solid;
}
::selection {
  background: $dark; /* Safari */
}
::-moz-selection {
  background: $dark; /* Firefox */
}

.colum {
  display: flex;
  flex-direction: column;
}
.textRed {
  color: $red;
}
.flex {
  display: flex;
  align-items: center;
}
.half {
  width: 50%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(29, 35, 46, 1) 10%,
    rgba(253, 187, 45, 0) 20%
  );
}
.container {
  max-width: 1170px;
  margin: 0 auto;
}
.rs {
  position: absolute;
  bottom: -10px;
  right: -25px;
  @include h700;
  font-size: 350px;
  color: #fff;
  line-height: 230px;
}
.rsAnimation {
  animation: rsAnim 3s ease-in-out infinite;
}
.bgRed {
  background: $red;
  border: none;
}
.delay {
  transition-delay: 2s;
}
.bgHome {
  position: absolute;
  top: 0;
  left: 0;
  background: url(../assets/images/bg.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@keyframes rsAnim {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes soaring {
  0%,
  100% {
    transform: translateX(0px) translateY(0px);
  }
  30% {
    transform: translateX(20px) translateY(-25px);
  }
  60% {
    transform: translateX(-10px) translateY(5px);
  }

  80% {
    transform: translateX(10px) translateY(20px);
  }
}
@media (max-width: $tablet-width) {
  .container {
    padding: 0 20px;
  }
}
@media (max-width: 869px) {
  .container {
    padding: 0 20px;
  }
  .colum {
    width: 100%;
  }
}
@media (max-width: $mobile-width) {
  .container {
    padding: 0 10px;
    box-sizing: border-box;
  }
  .center {
    margin-top: -210%;
  }
}
