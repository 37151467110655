.advantage__item {
  display: flex;
  align-items: center;
  height: 50px;
  color: $white;
  gap: 60px;
  &-icon {
    background: $red;
    min-width: 36px;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title {
    width: 100%;
  }
}

@media (max-width: $tablet-width) {
  .advantage__item {
    margin-bottom: 50px;
    padding: 0;
    gap: 40px;
    &-title {
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .advantage__item {
    padding: 0;
    &-title {
      font-size: 10px;
    }
  }
}
