#gallery {
  padding: 100px 0;
  background: url("../../assets/images/Gallery/bg.png") no-repeat center center
    $gray;
}

.gallery__inside {
  display: flex;
  justify-content: space-between;
  &-left.sticky {
    position: sticky;
    top: 50px;
  }

  &-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &-item {
    transition: all 0.5s;
    max-width: 370px;

    img {
      max-width: 100%;
    }

    &:hover {
      transform: translate(-15px, -10px);
      box-shadow: 11px 16px 21px 0px rgba(34, 60, 80, 0.2);
    }
  }
}
@media (max-width: $tablet-width) {
}

@media (max-width: 869px) {
  .gallery__inside {
    flex-direction: column;
    .block__info {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: $mobile-width) {
  #gallery {
    padding: 20px 0 50px 0;
  }
  .gallery__inside {
    font-size: 14px;
    padding: 0;
    flex-direction: column;
    &-images {
      grid-template-columns: 1fr;
    }
    .block__info {
      margin-bottom: 40px;
      &-title {
        font-size: 14px;
      }
      &-subtitle {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .button {
      width: 240px;
    }
  }
}
