.about__inside-value {
  @include s400;
  max-width: 570px;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 50px;
}
@media (max-width: $tablet-width) {
  .about__inside-value {
    font-size: 14px;
  }
}

@media (max-width: $mobile-width) {
  .about__inside-value {
    font-size: 12px;
    margin-bottom: 35px;
  }
}
