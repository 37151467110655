footer {
  padding: 95px 0px 75px 0px;
  box-sizing: border-box;
  position: relative;
  background: $dark;
  z-index: 1;
  .logo {
    margin-bottom: 50px;
    &_title {
      font-size: 18px;
    }
    &_subtitle {
      font-size: 11px;
    }
    svg {
      width: 60px;
      height: 60px;
    }
    &__icon {
      margin-right: 20px;
    }
  }
  .rs {
    transition: all 0.5s;
    bottom: -25px;
    left: -27px;
    font-size: 277px;
    background: linear-gradient(-60deg, #404752, #262c36, #262c36, #262c36);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .mt-title {
    margin-top: 80px;
  }
  a {
    cursor: pointer;
  }
  a:hover {
    color: $red;
    text-decoration: underline;
  }
}

.footer__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: $white;
  padding-bottom: 70px;
  box-sizing: border-box;
  overflow: hidden;
  .colum {
    z-index: 4;
  }
  
  .footer-leftside {
    li {
      @include s400;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  ul,
  .footer_schedule-time {
    display: flex;
    flex-direction: column;
    gap: 25px;
    &.footer_schedule {
      display: flex;
      flex-direction: column;
    }
    li {
      @include g400;
      font-size: 14px;
    }
  }

  .footer_schedule-tg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;

    svg#tg-icon {
      z-index: 3;
      path {
        fill: $whiteBg;
      }
    }
    svg#tg-icon-border {
      z-index: 2;
      path {
        fill: $whiteBg;
      }
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.5s;
      path {
        transition: all 0.5s;
      }
    }
    &:hover {
      svg#tg-icon {
        transform: translate(10px, -10px) scale(1.3);
        path {
          fill: $red;
        }
      }
      svg#tg-icon-border {
        transform: scale(0.9);
      }
    }
  }
}

.footer__title {
  @include s400;
  font-size: 14px;
  margin-bottom: 40px;
  color: $dark-blue;
}

.footer__bottom {
  color: $white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 70px;
  border-top: 1px solid #333842;
  p {
    @include s400;
    font-size: 16px;
  }
  .developers {
    display: flex;
    p {
      padding-right: 40px;
    }
  }
}
.footer_tg-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer__title {
    margin: 0;
  }
}
@media (max-width: $tablet-width) {
  #footer {
    .rs {
      font-size: 266px;
      bottom: -7%;
    }
  }
}

@media (max-width: $mobile-width) {
  footer {
    padding: 20px 0px 40px 0px;
    .footer__title,
    .mt-title {
      margin: 25px 0;
    }
    .logo {
      margin-bottom: 25px;
    }
  }
  .footer__header {
    flex-direction: column;
    ul {
      li {
        font-size: 12px;
      }
    }
    .colum:nth-child(2) {
      order: 3;
      margin-top: 25px;
    }
    .colum:nth-child(5) {
      order: 4;
      .footer_tg-row {
        justify-content: flex-start;
        .footer__title {
          margin-right: 40px;
        }
      }
      margin-top: 30px;
      a {
        margin: 0;
      }
    }
    .rs {
      display: none;
    }
  }
  .footer__bottom {
    flex-direction: column;
  }
  .footer__header ul,
  .footer__header .footer_schedule-time {
    gap: 15px;
  }
  .footer__header {
    padding-bottom: 25px;
  }
  .footer__bottom {
    p {
      font-size: 12px;
    }
    align-items: flex-start;
  }
  .footer__bottom {
    padding-top: 30px;
  }
}
