.vacancy {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: $white;
  gap: 40px;
  &-icon {
    img {
      max-width: 100%;
    }
  }

  &-title {
    @include g400;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 150%;
  }
  &-description {
    @include s400;
    line-height: 150%;
    font-size: 14px;
    max-width: 230px;
  }
}
