.slider-btn {
  transition: all 0.5s;
  width: 60px;
  height: 60px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      transition: all 0.5s;
      stroke: $red;
    }
  }
  &.prev {
    transform: rotate(180deg);
    margin-right: 15px;
  }
  &:hover {
    background: $red;
    svg {
      path {
        stroke: $white;
      }
    }
  }
}
.vacancy__slider-footer {
  width: 100%;
  background: $dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px 35px 35px;
  box-sizing: border-box;
}
.vacancy__slider-buttons {
  display: flex;
  align-items: center;
}
.vacancy__slider-footer-counter {
  color: $white;
  margin-left: 30px;
}
.vacancy__slider-readmore {
  cursor: pointer;
  font-size: 14px;
  text-decoration-line: underline;
  color: $white;
  @include g400;
}

@media (max-width: $tablet-width) {
  section#advantageslider .vacancy__slider-footer {
    padding: 0 0 40px 0;
  }
  .slider-btn {
    width: 40px;
    height: 40px;
  }
  .vacancy__slider-footer-counter,
  .vacancy__slider-readmore {
    font-size: 12px;
  }
}

@media (max-width: 869px) {
}

@media (max-width: $mobile-width) {
}
