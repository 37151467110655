.arrowUp {
  cursor: pointer;
  transition: all 0.5s;
  position: fixed;
  bottom: 20%;
  right: 10%;
  width: 60px;
  height: 60px;
  background: $red;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  svg {
    transform: rotate(270deg);
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: $mobile-width) {
  .arrowUp {
    width: 40px;
    height: 40px;
  }
}
