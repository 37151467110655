.vacancy__about {
  @include s400;
  display: flex;
  z-index: 99;
  justify-content: space-between;
  color: $dark;
  margin-top: -20%;
  position: relative;
  z-index: 99;
  margin-bottom: 5%;
  .colum:first-child {
    width: 70%;
    background: $whiteBg;
    padding: 70px;
    box-sizing: border-box;
  }
  .colum:last-child {
    justify-content: flex-end;
  }
  &-title {
    margin-bottom: 30px;
  }
  &-conditions-title {
    font-weight: bold;
  }
  &-conditions-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 15px 0 30px 0;
    .vacancy__about-condition {
      position: relative;
      left: 20px;
      &::before {
        content: "";
        position: absolute;
        left: -20px;
        top: 32%;
        border-radius: 100%;
        height: 8px;
        width: 8px;
        background: $red;
      }
    }
  }
}

@media (max-width: 869px) {
  .vacancy__about {
    .colum:first-child {
      width: 100%;
    }
    &-conditions {
      &-list {
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }
  }
}
