.direction__cart {
  background: #262c36;
  box-sizing: border-box;
  padding: 0px 100px 0px 0px;
  margin-bottom: 30px;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include h700;
    font-size: 20px;
  }
  &-image {
    width: 300px;
    min-height: 280px;
    overflow: hidden;
    img {
      transition: all 0.5s;
      width: 300px;
    }
  }
  &-number {
    color: $red;
    width: 20%;
    text-align: center;
  }
  &-title {
    width: 50%;
    color: $white;
  }
  &-showbtn {
    transition: all 0.5s;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transform: rotate(90deg);
      &.active {
        transform: rotate(-90deg);
      }
    }
    &:hover {
      background: $dark;
    }
  }
  &-inside {
    @include s400;
    padding: 0px 0px 0px 100px;
    overflow: hidden;
    height: 0;
    color: $white;
    transition: all 1s cubic-bezier(0.73, -0.02, 0.55, 0.87);
    &__list {
      display: flex;
      flex-direction: column;

      &-title {
        @include s400;
        font-weight: bold;
        margin: 30px 0px 15px 0px;
        line-height: 150%;
      }
      &-item {
        position: relative;
        left: 2%;
        margin-bottom: 5px;
        &::before {
          content: "";
          position: absolute;
          top: 35%;
          left: -2%;
          width: 8px;
          height: 8px;
          background: $red;
          border-radius: 100%;
        }
      }
    }
    .hidecontent {
      padding: 40px 0;
      line-height: 27px;
    }
  }
  &:hover {
    .direction__cart-image {
      img {
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .direction__cart {
    padding: 40px;
    margin-bottom: 15px;
    &-image {
      max-width: 100%;
    }
    &-number,
    &-title {
      font-size: 14px;
    }
    &-showbtn {
      width: 40px;
      height: 40px;
    }
    &-inside {
      &-title {
        font-size: 11px;
      }
      &__list {
        &-title,
        &-item {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 869px) {
  .direction__cart {
    padding: 27px;
    &-title {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 30px;
    }
    &-number {
      margin-top: 30px;
      text-align: left;
    }
    &-top {
      flex-direction: column;
      align-items: flex-start;
    }
    &-inside {
      margin-top: 30px;
      padding: 0;
      &__list {
        &-item {
          left: 5%;
          &::before {
            top: 40%;
            left: -5%;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-width) {
}
