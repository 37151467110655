#about {
  position: relative;
  z-index: 9;
  background: $whiteBg;
  .button {
    background: $red;
    &::before {
      background: $dark;
    }
  }
  .rs {
    background: none;
    -webkit-text-fill-color: #fff;
    path {
      fill: #fff;
    }
  }
}
.about__call,
.company__call {
  position: relative;
  width: 100%;
  background: $red;
  padding: 80px 100px;
  overflow: hidden;
  box-sizing: border-box;
  transform: translateY(-30%);
}

.about__title {
  display: flex;
  flex-direction: column;
  @include h700;
  font-size: 24px;
  text-transform: uppercase;
  color: $white;
  span {
    text-decoration: underline;
  }
  .button {
    width: 100px;
  }
}

.about__inside {
  display: flex;
  justify-content: space-between;
  .colum:nth-child(1) {
    width: 50%;
  }
  .colum:nth-child(2) {
    width: 50%;
  }
  &-logo {
    max-width: 470px;
    margin: 40px 0px 90px 0;
    img {
      max-width: 100%;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0px 0px 0;
  }
}

@media (max-width: $tablet-width) {
  #about {
    .about__call {
      .rs {
        font-size: 166px;
        bottom: -19%;
      }
    }
  }
}

@media (max-width: 869px) {
  .about__inside {
    flex-direction: column;
    padding-bottom: 70px;
  }
}

@media (max-width: $mobile-width) {
  #about {
    .about__call {
      padding: 0;
      height: 350px;
      padding: 30px;
      //transform: translateY(-30%);
      .rs {
        font-size: 230px;
        bottom: -16%;
        left: 44%;
        transform: translateX(-50%);
      }
    }
    .about__title {
      font-size: 18px;
    }
    .about__inside {
      flex-direction: column;
      padding-bottom: 70px;
      .colum:nth-child(1) {
        width: 100%;
      }
      .colum:nth-child(2) {
        width: 100%;
      }
      &-content {
        padding: 0;
      }
    }
    .about__inside-logo {
      margin: 0px 0px 35px 0;
    }
  }
}
