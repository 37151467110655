.menu__btn {
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 18px;
  display: flex;
  align-items: center;
  &:hover {
    .menu__btn-burger::before,
    .menu__btn-burger::after {
      width: 100%;
    }
  }
  &.active {
    .menu__btn-burger {
      background: transparent;
      &::after,
      &::before {
        width: 100%;
      }
      &::before {
        transform: rotate(45deg) translateX(1px);
      }
      &::after {
        transform: rotate(-45deg) translate(11px, -10px);
      }
    }
  }
}

.menu__btn-burger {
  transition: all 0.5s;
  width: 100%;
  height: 2px;
  background: $white;
  &::before,
  &::after {
    transition: all 0.5s;
    content: "";
    position: absolute;
    width: 50%;
    height: 2px;
    background: $white;
  }
  &::before {
    top: 0;
    left: 0;
  }
  &::after {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: $mobile-width) {
  .hamburger__btn {
    margin: 0;
  }
}
