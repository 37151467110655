.advantage__title {
  @include h700;
  font-size: 20px;
  text-transform: uppercase;
  color: $white;
  line-height: 150%;
  margin-bottom: 70px;
  max-width: 520px;
  span {
    background: $red;
    padding: 5px 10px;
  }
  .preview__text {
    margin-bottom: 65px;
  }
}

@media (max-width: $mobile-width) {
  .advantage__title {
    font-size: 14px;
  }
}
