.fullsizeMenu {
  transition: all 1s;
  transition-delay: 0.5s;
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  background: $red;
  z-index: 999;
  overflow: hidden;
  .pattern {
    transition: all 2s;
    transition-delay: 0.5s;
    position: absolute;
    bottom: -100%;
    &.active {
      bottom: -20%;
    }
  }
  &.active {
    height: calc(100vh - 112px);
  }
  li {
    @include s400;
    color: $white;
  }
}

.fullsize__navigation {
  padding: 50px 20px 0 20px;
  box-sizing: border-box;
  li {
    font-size: 20px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &:last-child {
      border-bottom: 0;
    }
  }
  &-contacts {
    padding: 50px 20px 0 20px;
    box-sizing: border-box;
    li {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}
