section#notfound {
  background: $whiteBg;
  position: relative;
  overflow: hidden;
  height: 100vh;
  .pattern {
    position: absolute;
    bottom: -20%;
    left: 0;
    svg {
      path {
        fill: $whiteBg;
        stroke: $dark;
      }
    }
  }
}

.notfound__intro {
  position: relative;
  z-index: 123;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 50px;
  @include h700;
  &-title {
    color: $red;
    font-size: 240px;
    width: 50%;
  }
  &-car {
    width: 50%;
    img {
      position: relative;
      transform: translateX(-200%);
      animation: carAnim 3s forwards;
    }

    &::before {
      content: "";
      position: absolute;
      width: 951px;
      height: 281px;
      background: url(../../assets/images/Advantage/car.png) no-repeat;
      animation: carAnimTwo 2s 2s forwards;
      transform: translateX(-200%);
    }
  }
  &-row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 10%;
    }
  }
  &-subtitle {
    max-width: 452px;
    font-size: 36px;
    text-transform: uppercase;
    span {
      color: $red;
    }
  }
}

@keyframes carAnim {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes carAnimTwo {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-40%);
  }
}

@media (max-width: $tablet-width) {
  .notfound__intro {
    &-title {
      font-size: 168px;
    }
    &-car {
      img {
        width: 700px;
        height: 230px;
      }
      &::before {
        width: 700px;
        height: 230px;
        background-size: contain;
      }
    }
    &-subtitle {
      font-size: 25px;
    }
  }
}

@media (max-width: 869px) {
  .notfound__intro {
    position: relative;
    &-row {
      flex-direction: column;
    }
    &-car {
      position: absolute;
      top: 30px;
      left: 0;
      &::before {
        display: none;
      }
    }
    &-subtitle {
      text-align: center;
      margin-bottom: 30px;
    }
    &-title {
      font-size: 114px;
    }
  }
}

@media (max-width: $mobile-width) {
}
