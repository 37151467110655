.about__inside-prevText {
  @include h700;
  font-size: 20px;
  line-height: 150%;
  color: $dark;
  padding-top: 50px;
  text-transform: uppercase;
  width: 783px;
  margin-left: -100px;
  span {
    color: $red;
  }
}

@media (max-width: $tablet-width) {
  .about__inside-prevText {
    font-size: 14px;
  }
}

@media (max-width: $mobile-width) {
  .about__inside-prevText {
    font-size: 14px;
    padding: 0;
    max-width: 783px;
    width: auto;
    margin: 0 0 35px 0;
  }
}
