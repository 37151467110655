.advantageSlideContent {
  max-width: 470px;
  color: $white;
  &-icon {
    width: 60px;
    height: 60px;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title {
    @include h700;
    font-size: 20px;
    text-transform: uppercase;
    margin: 40px 0 30px 0;
  }
  &-subtitle {
    @include s400;
    font-size: 14px;
    line-height: 150%;
  }
}

@media (max-width: $tablet-width) {
  .advantageSlideContent {
    &-icon {
      width: 50px;
      height: 50px;
    }
    &-title {
      font-size: 14px;
    }
    &-subtitle {
      font-size: 9px;
    }
  }
}

@media (max-width: 869px) {
}

@media (max-width: $mobile-width) {
}
