.logo {
  text-align: center;
  color: $white;
  &__icon {
    margin-bottom: 5px;
  }
  &_title {
    font-size: 16px;
    text-transform: uppercase;
    @include g400;
  }
  &_subtitle {
    font-size: 10px;
  }
}

@media (max-width: $mobile-width) {
  .logo {
    svg {
      width: 22px;
      height: 22px;
    }
    &__icon {
      margin: 0;
    }
    &_title {
      font-size: 13px;
    }
    &_subtitle {
      font-size: 8px;
    }
  }
}
