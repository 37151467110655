section#advantageslider {
  display: flex;
  justify-content: space-between;
  .vacancy__slider-footer {
    padding: 0 0 120px 0;
  }
}

.advantageslider {
  &-bg,
  &-content {
    position: relative;
    width: 50%;
    height: 20%;
  }
  &-content {
    box-sizing: border-box;
    padding: 120px 120px 0px 100px;
    position: relative;
    background: $dark;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pattern {
      position: absolute;
      bottom: -30%;
      right: -55%;
      svg {
        width: 200%;
      }
    }
    .vacancy__slider-footer {
      z-index: 20;
      background: transparent;
    }
  }
}

.advantageSliderBg {
  max-height: 730px;
  img {
    width: 100%;
  }
}

@media (max-width: $tablet-width) {
  .advantageslider {
    &-content {
      padding: 100px 80px 0px 80px;
    }
  }
}

@media (max-width: 869px) {
  section#advantageslider {
    flex-direction: column;
  }
  .advantageslider-bg,
  .advantageslider-content {
    width: 100%;
  }
  .advantageslider-content {
    transform: translateY(-6px);
    padding: 45px 45px 0px 20px;
  }
  .vacancy__slider-footer {
    flex-direction: row;
    .vacancy__slider-readmore {
      margin: 0;
    }
  }
  .advantageSlideContent-subtitle {
    margin-bottom: 45px;
  }
}

@media (max-width: $mobile-width) {
}
