.pattern {
  position: relative;
  z-index: 3;
  width: 100%;
}
#cylinder10 {
  animation: start 1.6s linear infinite;
}
#cylinder26 {
  animation: start 1s linear infinite;
}
#cylinder12 {
  animation: start 1.5s linear infinite;
}
#cylinder21 {
  animation: start 1.1s linear infinite;
}
#cylinder28 {
  animation: start 1s linear infinite;
}
#cylinder23 {
  animation: startSecond 1.2s linear infinite;
}
#cylinder20 {
  animation: startSecond 1.4s linear infinite;
}
#cylinder17 {
  animation: start 1.4s linear infinite;
}
#cylinder9 {
  animation: startSecond 1.4s linear infinite;
}
#cylinder6 {
  animation: startSecond 1.2s linear infinite;
}
#cylinder2 {
  animation: startSecond 1.4s linear infinite;
}
#cylinder22 {
  animation: start 1.4s linear infinite;
}
#cylinder8 {
  animation: startUpCylinder 1.4s linear infinite;
}
#cylinder7 {
  animation: startUpCylinder 1s linear infinite;
}
#cylinder15,
#cylinder25 {
  animation: startUpCylinder 1s linear infinite;
}
#cylinder35 {
  animation: start 1.5s linear infinite;
}
#cylinder33 {
  animation: start 1.3s linear infinite;
}
#cylinder19 {
  animation: startUpCylinder 2s linear infinite;
}
#cylinder13 {
  animation: startSecond 1s linear infinite;
}
#cylinder11 {
  animation: startSecond 0.9s linear infinite;
}
#cylinder30 {
  animation: start 0.9s linear infinite;
}
#cylinder4 {
  animation: startUpCylinder 1.5s linear infinite;
}
#cylinder3 {
  animation: startUpCylinder 1.3s linear infinite;
}
#cylinder {
  animation: startUpCylinder 1.6s linear infinite;
}
#cylinder5 {
  animation: startSecond 1.4s linear infinite;
}
#cylinder14 {
  animation: startSecond 1.2s linear infinite;
}
#cylinder18 {
  animation: startSecond 1s linear infinite;
}
#cylinder16 {
  animation: startSecond 1s linear infinite;
}
#cylinder24,
#cylinder27 {
  animation: startSecond 1.4s linear infinite;
}
#cylinder34 {
  animation: startSecond 2s linear infinite;
}
#cylinder29 {
  animation: startSecond 2s linear infinite;
}
#cylinder36 {
  animation: startSecond 1.4s linear infinite;
}
#cylinder31,
#cylinder32 {
  animation: startSecond 1.4s linear infinite;
}

@keyframes start {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-45px);
  }
}

@keyframes startSecond {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
}

@keyframes startUpCylinder {
  0%,
  100% {
    transform: translateY(25px);
  }
  50% {
    transform: translateY(0px);
  }
}

@media (max-width: $tablet-width) {
  .pattern {
    width: 100%;
    position: relative;
    overflow: hidden;
    svg {
      position: relative;
      left: -20%;
      width: 150%;
    }
  }
}

@media (max-width: $mobile-width) {
  .pattern {
    width: 100%;
    position: relative;
    overflow: hidden;
    svg {
      position: relative;
      left: -20%;
      width: 300%;
    }
  }
}
