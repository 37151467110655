section#history {
  background: $whiteBg;
  box-sizing: border-box;
  padding-top: 220px;
  position: relative;
  z-index: 1;
}

@media (max-width: 869px) {
  section#history {
    padding-top: 50px;
  }
}
