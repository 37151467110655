.block__info {
  display: flex;
  flex-direction: column;
  width: 330px;
  &-title {
    @include h700;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 150%;
    margin-bottom: 30px;
    max-width: 370px;
    span {
      color: $red;
    }
  }
  &-subtitle {
    @include s400;
    font-size: 16px;
    line-height: 150%;
    color: $dark;
    margin-bottom: 60px;
  }
  .button {
    width: 240px;
  }
}
.preview__text {
  margin-bottom: 60px;
}

@media (max-width: $mobile-width) {
  .preview__text {
    margin-bottom: 40px;
  }
}

@media (max-width: $tablet-width) {
  .preview__text {
    margin-bottom: 40px;
  }
  .block__info-subtitle,
  .block__info-title {
    font-size: 14px;
  }
}
