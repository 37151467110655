main {
  position: relative;
  z-index: 2;
  padding-top: 22%;
  .button {
    background: #353a44;
  }
  .advantage__item {
    gap: 25px;
    &-icon {
      min-width: 44px;
      min-height: 44px;
    }
  }
}
.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.intro__title {
  @include h700;
  color: $white;
  font-size: 36px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-transform: uppercase;
  max-width: 700px;
  span {
    color: $red;
  }
}

@media (max-width: $tablet-width) {
  main {
    padding-top: 40%;
  }
}

@media (max-width: 869px) {
  main {
    padding-top: 25%;
  }
  .intro__title {
    font-size: 24px;
  }
  .intro {
    flex-direction: column;
    align-self: flex-start;
    align-items: baseline;
  }
}

@media (max-width: $mobile-width) {
  main {
    padding-top: 45%;
  }
  .intro {
    flex-direction: column;
    margin-bottom: 20%;
    align-items: initial;
    &__title {
      font-size: 16px;
      margin: 30px 0px;
    }
  }
}
