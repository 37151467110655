section#company {
  padding: 120px 0;
  background: $whiteBg;
  .rs {
    background: linear-gradient(-60deg, #a83f3f, #bc4949, #bc4949, #bc4949);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .bgRs {
    position: absolute;
    background: $red;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &-line {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-100%);
      animation: teststart 5s linear infinite;
    }
    .rs {
      position: relative;
      font-size: 370px;
      line-height: 270px;
      right: 27px;
      bottom: 0;
    }
    .badge {
      transition: all 2s;
      transition-delay: 1s;
      position: absolute;
      top: 35%;
      right: -100%;
      &.active {
        top: 35%;
        right: 0;
      }
    }
  }
}

.company {
  &__inside {
    display: flex;
    justify-content: space-between;
    position: relative;
    .colum:nth-child(1) {
      width: 100%;
    }
  }
  &__title {
    max-width: 450px;
    @include h700;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 70px;
    span {
      color: $red;
    }
  }
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-top: 1px solid #e2e3e4;
    border-left: 1px solid #e2e3e4;
    width: 100%;
    z-index: 99;
    &-item {
      transition: all 0.5s;
      width: 100%;
      height: 295px;
      background: $whiteBg;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #e2e3e4;
      border-bottom: 1px solid #e2e3e4;
      box-sizing: border-box;
      box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
      &:hover {
        animation: normal;
        transform: scale(1.1);
        z-index: 1;
      }
    }
  }
  &1 {
    animation: scaleComapnyAnim 2s infinite;
  }
  &2 {
    animation: scaleComapnyAnim 1.3s infinite;
  }
  &3 {
    animation: scaleComapnyAnim 1s infinite;
  }
  &4 {
    animation: scaleComapnyAnim 1.6s infinite;
  }
  &5 {
    animation: scaleComapnyAnim 1.2s infinite;
  }
  &6 {
    animation: scaleComapnyAnim 1.7s infinite;
  }
  &7 {
    animation: scaleComapnyAnim 1.5s infinite;
  }
}

.company__call {
  position: relative;
  margin: 0;
  transform: translateY(0%);
  margin-top: 120px;
  width: 100%;
  &-inside {
    max-width: 450px;
    color: $white;
  }
  &-title {
    @include h700;
    font-size: 24px;
    line-height: 150%;
    text-transform: uppercase;
  }
  &-subtitle {
    @include s400;
    font-size: 16px;
    margin-top: 30px;
  }
}

@keyframes teststart {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(110%);
  }
}

@keyframes scaleComapnyAnim {
  0%,
  100% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
}

@media (max-width: 869px) {
  section#company {
    .bgRs {
      display: none;
    }
    .company__call {
      padding: 60px 50px;
    }
  }
  .company {
    &__title {
      font-size: 14px;
    }
    &-list {
      grid-template-columns: 1fr 1fr;
      &-item {
        height: 140px;
      }
    }
  }
}
