.about__call,
.company__call {
  &-image {
    position: absolute;
    top: -20%;
    right: 8%;
    z-index: 1;
    img {
      max-width: 100%;
    }
  }
  .rs {
    background: linear-gradient(-60deg, #a83f3f, #bc4949, #bc4949, #bc4949);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .about__title {
    max-width: 500px;
  }
  .about-btn {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    @include g400;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    margin-top: 60px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: #fff;
    }
    &::before {
      transition: all 2s;
      top: 0;
      left: -100%;
      width: 180px;
      height: 100%;
    }
    &::after {
      transition: all 2s;
      width: 60%;
      height: 1px;
      bottom: 0;
      left: -100%;
    }
    img {
      width: 30px;
      height: 30px;
    }
    &-main {
      transition: all 0.5s 0.5s;
      img {
        margin-right: 20px;
      }
    }
    &-hide {
      transition: all 0.5s 0.5s;

      position: absolute;
      opacity: 0;
    }
    &:hover {
      .about-btn-hide {
        opacity: 1;
      }
      .about-btn-main {
        opacity: 0;
      }
      &::before {
        left: 90%;
      }
      &::after {
        left: 0;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .about__call {
    padding: 40px 70px;
    .rs {
      font-size: 250px;
      right: -11px;
      bottom: -29px;
    }
    &-image {
      max-width: 285px;
      img {
        max-width: 100%;
      }
    }
    .about__title {
      max-width: 360px;
      font-size: 16px;
    }
  }
}

@media (max-width: 869px) {
  .about__call {
    height: 459px;
    flex-direction: column;
    padding: 30px;
    .rs {
      font-size: 150px;
    }
    &-image {
      width: 400px;
      bottom: 40px;
      right: 22%;
      transform: translateX(50%);
      top: initial;
    }
    .about__title {
      max-width: 225px;
    }
  }
}

@media (max-width: $mobile-width) {
}
