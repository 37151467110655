header {
  position: relative;
  z-index: 3;
  color: $white;
  @include s400;
  padding: 30px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 1s;
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 0) 0%,
    rgba(29, 35, 46, 0.48503151260504207) 63%
  );
  ul {
    display: flex;
    padding-inline-start: 0px;
    gap: 30px;
  }
}

.header__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  li,
  a {
    cursor: pointer;

    &:hover {
      color: $red;
    }
  }
  li,
  a:first-child {
    margin-left: 0;
  }
  li,
  a:last-child {
    margin-right: 0;
  }
  &-contacts {
    display: flex;
    align-items: center;
  }
  li {
    position: relative;
    overflow: hidden;
    height: 30px;
    transition: all 0.5s;
    &::after,
    &::before {
      transition: all 0.5s;
      content: "";
      position: absolute;
      height: 2px;
      background: $red;
    }
    &::before {
      width: 70%;
      left: 0;
      bottom: 3px;
      transform: translateX(-100%);
    }
    &::after {
      width: 60%;
      right: 0;
      bottom: 8px;
      transform: translateX(100%);
    }
    &:hover {
      &::before,
      &::after {
        transform: translateX(0);
      }
    }
  }
  .lactive-class {
    color: $red;
    font-weight: bold;
    li {
      &::before {
        transform: translateX(0%);
      }
      &::after {
        transform: translateX(0%);
      }
    }
  }
}
// .dark {
//   background: none;
//   li,
//   a {
//     color: $dark;
//   }
//   .logo {
//     svg {
//       path {
//         fill: $red;
//       }
//     }
//     &__bottom {
//       color: $dark;
//     }
//   }
//   border-bottom: 1px solid rgba(29, 35, 46, 0.1);
//   .menu__btn-burger {
//     background: $red;
//     &::before,
//     &::after {
//       background: $red;
//     }
//   }
//   .callBtn {
//     path {
//       fill: $red;
//     }
//   }
// }
@media (max-width: $tablet-width) {
  header {
    font-size: 14px;
    li,
    a {
      font-size: 15px;
    }
  }
}

@media (max-width: 869px) {
  .header__menu {
    &-navigation,
    &-contacts {
      display: none;
    }
  }
}

@media (max-width: $mobile-width) {
  header {
    height: 50px;
  }
  .header__menu {
    &-navigation,
    &-contacts {
      display: none;
    }
  }
}
