section#contactIntro {
  .pattern {
    position: absolute;
    top: 40%;
    z-index: -1;
  }
}

.contact {
  &__title {
    display: flex;
    flex-direction: column;
    @include h700;
    font-size: 36px;
    text-transform: uppercase;
    color: $white;
    max-width: 1000px;
    margin: 100px 0 115px 0;
    span:last-child {
      color: $red;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-bottom: 100px;
  }
  &__cart {
    transition: all 0.5s;
    position: relative;
    background: #262c36;
    height: 244px;
    box-sizing: border-box;
    padding: 60px;
    @include s400;
    &:nth-child(1) {
      position: relative;
      z-index: 1;
    }
    &:nth-child(2) {
      position: relative;
      z-index: 2;
    }
    &:nth-child(3) {
      position: relative;
      z-index: 3;
    }
    &-car {
      transition: all 1s;
      position: absolute;
      bottom: 0;
      right: -40%;
      z-index: 2;
      opacity: 0;
      img {
        width: 200px;
      }
    }
    &-subtitle {
      transition: all 0.5s;
      a {
        transition: all 0.5s;
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 80%;
      height: 5px;
      background: $whiteBg;
      z-index: 1;
      opacity: 0;
    }
    &::before {
      transition: all 0.5s;
      left: -100px;
      top: -5px;
    }
    &::after {
      transition: all 0.5s;
      right: -100px;
      bottom: -5px;
    }
    &-icon {
      position: relative;
      z-index: 2;
      transition: all 0.5s;
      margin-bottom: 42px;
    }
    &-title {
      margin-bottom: 15px;
      color: $dark-blue;
    }
    &:hover {
      transform: scale(0.95);
      .contact__cart-icon {
        transform: translateY(-0px);
      }
      .contact__cart-car {
        right: 0;
        opacity: 1;
      }
      .contact__cart-icon {
        transform: scale(1.9) translateX(20%);
      }
      .contact__cart-subtitle {
        transform: translateX(10px);
        a {
          color: $red;
        }
      }
      &::before {
        left: -30px;
        opacity: 1;
      }
      &::after {
        right: -30px;
        opacity: 1;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .contact {
    &__title {
      font-size: 25px;
      max-width: 700px;
      margin: 100px 0 44px 0;
    }
    &__cart {
      padding: 40px;
      height: 172px;
      &-icon {
        width: 16px;
        height: 16px;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
      &-title {
        margin-bottom: 10px;
      }
      &-title,
      &-subtitle {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 869px) {
  #contactIntro {
    overflow: hidden;
  }
  .contact {
    &__title {
      font-size: 18px;
      max-width: 215px;
      margin: 100px 0 44px 0;
    }
    &__list {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: $mobile-width) {
}
