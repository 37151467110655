#aboutcounter {
  background: $whiteBg;
}
.counter {
  position: relative;
  z-index: 22;
  display: flex;
  justify-content: space-between;
  background: $red;
  width: 80vw;
  height: 700px;
  transform: translateY(-50%);
  .colum:first-child {
    padding: 100px 0px 0px 100px;
    box-sizing: border-box;
    width: 60%;
  }
  .colum:last-child {
    width: 40%;
    overflow: hidden;
    height: 100%;
  }
  .aboutCounter-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  &-statistics {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: 555px;
    .about__inside-status-title,
    .about__inside-status-subTitle {
      color: $white;
    }
  }
  .preview__text {
    color: rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: $tablet-width) {
  .counter .colum:first-child {
    padding: 100px 32px 0px 50px;
    box-sizing: border-box;
  }
}

@media (max-width: 868px) {
  .counter {
    width: 96vw;
    flex-direction: column-reverse;
    .colum:first-child,
    .colum:last-child {
      width: 100%;
      padding: 0;
    }
    .colum:first-child {
      padding: 45px 20px 75px 20px;
    }
  }
}
