.map__inside {
  position: absolute;
  margin-top: -20px;
  z-index: 1;
  display: flex;
  gap: 30px;
}

.map__cart {
  cursor: pointer;
  transition: all 0.5s;
  width: 370px;
  height: 329px;
  background: $gray;
  z-index: 1;
  box-sizing: border-box;
  padding: 50px 60px;
  color: $dark;
  @include g400;
  span {
    color: $red;
  }
  &-title {
    transition: all 0.5s;
    margin-bottom: 20px;
    font-size: 20px;
    text-transform: uppercase;
  }
  &-subtitle {
    transition: all 0.5s;
    margin-bottom: 54px;
    height: 80px;
    @include s400;
  }
  &-btn {
    transition: all 0.5s;
    text-decoration: underline;
    cursor: pointer;
    color: $red;
  }
  &.active {
    background: $red;
    color: $white;
    span,
    .map__cart-btn {
      color: $white;
    }
  }
  &:hover {
    transform: translate(-15px, -10px);
    box-shadow: 11px 16px 21px 0px rgba(34, 60, 80, 0.2);
    .map__cart-title {
      transform: translateX(20px) scale(1.05);
    }
    .map__cart-btn {
      transform: translateX(-20px) scale(1.05);
    }
    .map__cart-subtitle {
      transform: translateY(10px);
    }
  }
}
.ymaps-2-1-79-map-copyrights-promo {
  display: none;
}
@media (max-width: $tablet-width) {
  .map__cart {
    height: auto;
    width: 260px;
    padding: 40px 50px;
    &-title {
      font-size: 14px;
      margin-bottom: 15px;
    }
    &-subtitle {
      font-size: 12px;
      margin-bottom: 36px;
      height: 70px;
    }
    &-btn {
      font-size: 12px;
    }
  }
}

@media (max-width: 869px) {
  #contactIntro {
    margin-bottom: 130%;
  }
  .map {
    &__inside {
      left: 50%;
      gap: 15px;
      flex-direction: column;
      transform: translate(-50%, -90%);
    }
  }
}

@media (max-width: $mobile-width) {
}
