section#geography {
  position: relative;
  background: $white;
  overflow: hidden;
  .krsk:hover ~ .geography__map {
    opacity: 0;
  }
  svg {
    path {
      transition: all 0.5s;
    }
  }
}
.geography {
  &__inside {
    display: flex;
    justify-content: space-between;
  }
  &__adress,
  &__map {
    box-sizing: border-box;
    padding: 120px 0;
    width: 50%;
    z-index: 1;
  }
  &__map {
    transform: translateX(0%);
  }
  &__title {
    cursor: pointer;
    max-width: 277px;
    @include h700;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 200px;
    span {
      color: $red;
    }
  }

  &__location-title {
    @include s400;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 150%;
    margin-top: 15px;
    color: #000;
    span {
      color: $dark-blue;
    }
  }
}

.galleryBg {
  position: absolute;
  top: 0;
  right: 0;
  background: url(../../assets/images/Geography/bgGeography.jpg) no-repeat;
  background-size: cover;
  height: 100%;
  width: 50%;
}

@media (max-width: $tablet-width) {
}

@media (max-width: 869px) {
  .geography__inside {
    flex-direction: column;
  }
  .galleryBg {
    height: 50%;
    height: 50%;
    bottom: 0;
    top: inherit;
  }
  .geography__adress,
  .geography__map {
    box-sizing: border-box;
    width: 100%;
    z-index: 1;
    svg {
      width: 400px;
      height: 322px;
    }
  }
  .geography__map {
    padding: 0;
  }
  .geography__adress {
    padding: 120px 0 0 0;
  }
  .geography__title {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .geography__location-title {
    font-size: 12px;
  }
}

@media (max-width: $mobile-width) {
}
