#advantages {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
  z-index: 4;
  margin-bottom: 15%;
  .columgap {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
}
.advantages__car {
  position: absolute;
  bottom: -20px;
  left: 0;
  left: -50%;
  z-index: 2;
}

@media (max-width: 869px) {
  #advantages {
    flex-direction: column;
    overflow: hidden;
    .columgap {
      gap: 0;
    }
  }
  .advantages__car {
    position: relative;
    bottom: 0px;
    left: 0;
    left: 0%;
  }
}

@media (max-width: $mobile-width) {
  #advantages {
    flex-direction: column;
    overflow: hidden;
    margin-top: 90%;
  }
  .advantages__car {
    position: relative;
    bottom: 0px;
    left: 0;
    left: -60%;
    img {
      width: 500px;
    }
  }
}
