.showreal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  max-width: 100vw;
  max-height: 100vh;
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }
}
